<template>
    <div class="cooperationForPhone">
        <img src="../assets/images/News/u2099_div.png" width="100%"/>
        <div class="top-tab-dv">
            <div
                class="top-tab-dv-item"
                :class="{ active: active == '*' }"
                @click="active = '*'"
            >
                {{ $t('lang.AllNews') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="{ active: active == '0' }"
                @click="active = 0"
            >
                {{ $t('lang.XiaokeHeadline') }}
            </div>
            <div
                class="top-tab-dv-item"
                :class="{ active: active == '1' }"
                @click="active = 1"
            >
                {{ $t('lang.IndustryNews') }}
            </div>
        </div>
        <div class="device-img-dv">
            <div
                class="device-img-item-dv"
                v-for="(item, index) in getShow"
                :key="index"
            >
                <img :src="item.imgUrl"/>
                <div class="text-dv">
                    <div class="text-1">{{ item.title }}</div>
                    <div class="text-2">{{ item.info }}</div>
                    <div class="text-3">{{ item.releaseTime }}</div>
                </div>
            </div>
        </div>

        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";
import {getWebsiteNewsInfo} from "@/api";

export default {
    name: "cooperationForPhone",
    components: {
        AddressForPhone,
    },
    data() {
        return {
            show: true,
            active: "*",
            showData: [],
        };
    },
    mounted() {
        getWebsiteNewsInfo().then((res) => {
            this.showData = res.data.data.map((val) => {
                val["show"] = true;
                return val;
            });
            console.log("showData===>", this.showData);
        });
    },
    computed: {
        getShow() {
            if (this.active == "*") {
                return this.showData;
            } else {
                console.log(this.showData.filter((val) => val.type == this.active));
                return this.showData.filter((val) => val.type == this.active);
            }
        },
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.top-tab-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
    background: rgba($color: #000000, $alpha: 1);
    box-sizing: border-box;
    border-top: solid 1px #444444;
    font-weight: 700;
    width: 100%;
    margin-top: -5px;
    font-size: 0.6rem;
}

.top-tab-dv-item {
    width: 33%;
    border: solid 1px #444444;
}

.active {
    color: #a0f31e;
    border-bottom: solid 2px #a0f31e;
}

.device-img-dv {
    width: 90%;
    margin: auto;
}

.device-img-item-dv {
    position: relative;
    margin-top: 10px;

    img {
        width: 100%;
    }
}

.text-dv {
    position: absolute;
    bottom: 5px;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
    padding: 10px;
    background: rgba($color: #000000, $alpha: 0.4);
}

.text-1 {
    font-size: 0.9rem;
}

.text-2 {
    font-size: 0.8rem;
    margin-top: 5px;
}

.text-3 {
    font-size: 0.6rem;
    margin-top: 5px;
}
</style>
